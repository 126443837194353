import { Button, Typography, Box } from '@mui/material';

const ContactUs = () => {
    const handleEmailClick = () => {
        const email = "info" + "@" + "krakemtech.com";
        window.location.href = "mailto:" + email;
    }

    return (
        <Box mt={4}>
            <Typography variant="h5" color="textSecondary" align="center">
                Contact Us
            </Typography>
            <Button color="secondary" onClick={handleEmailClick}>
                Send us an email
            </Button>
        </Box>
    );
};

export default ContactUs;
