import React, {useState, useEffect} from 'react';
import { Container, Typography, Grid, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Footer from './Footer';
import ContactUs from './Contact';
import Fade from '@mui/material/Fade';


const Home = () => {
    const theme = useTheme();

    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        const img = new Image();
        img.src = `${process.env.PUBLIC_URL}/logo.png`;
        img.onload = () => setImageLoaded(true);
        // Optionally: handle error in case image cannot be loaded
        // img.onerror = () => handleImageLoadError();
    }, []);


    return (
        <>

        {imageLoaded ? (
            <Fade in={imageLoaded} timeout={500}>
        <div style={{ backgroundColor: theme.palette.primary.main }}>
        <Container>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={4}
                style={{ minHeight: '100vh' }}
            >
                <Grid item>
                    <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="KrakemTech Logo" width="256" height="256" style={{ maxWidth: '100%'}}/>
                </Grid>
                <Grid item>
                    <Typography variant="h4" color="secondary" align="center" gutterBottom>
                        KrakemTech, LLC
                    </Typography>
                    <Typography variant="h5" color="textSecondary" align="center" paragraph>
                        Unleashing the Future, Byte by Byte!
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h6" color="secondary" align="center" paragraph>
                        What We Provide
                    </Typography>
                    <Box textAlign="center">
                        <Typography color="textSecondary" paragraph>
                            <strong>Consulting:</strong>   Specializing in Cloud Architecture, Site Reliability Engineering, and more.
                        </Typography>
                        <Typography color="textSecondary" paragraph>
                            <strong>Programming:</strong> Custom software solutions tailored to your needs.
                        </Typography>
                        <Typography color="textSecondary" paragraph>
                            <strong>Support:</strong> Dedicated assistance to ensure smooth operations.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <ContactUs/>
                </Grid>
                <Grid item>
                    <Footer />
                </Grid>
            </Grid>
        </Container>
        </div>
            </Fade>
        ): (
            <></>
        )}
        </>
    );
};

export default Home;
