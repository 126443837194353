import React from 'react';
import Home from './components/Home';
const App = () => {
  return (
      <div className="App">
          <Home />
      </div>
  );
};

export default App;