import React from 'react';
import ReactDOM from 'react-dom/client';
//import './index.css';
import App from './App';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#1E3A5F', // Dark blue derived from the background
        },
        secondary: {
            main: '#35A7D4', // Light blue from the hexagonal shape
        },
        text: {
            primary: '#000000', // Black from the swirling design
            secondary: '#B0BEC5', // White from the swirling design
        },
    },
    // ... add other theme customizations if necessary
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={theme}>

    <React.StrictMode>
    <App />
  </React.StrictMode>
    </ThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

