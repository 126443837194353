import { Typography, Box } from '@mui/material';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <Box mt={4} py={2} bgcolor="primary.main" color="text.secondary">
            <Typography variant="body2" align="center">
                © {currentYear} KrakemTech, LLC. All Rights Reserved.
            </Typography>
        </Box>
    );
};

export default Footer;
